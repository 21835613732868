import React, { useState } from 'react';
import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import './TopBar.css'


const TopBar = props => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
  
    return (
      <div>
          <Container fluid>
            <Navbar color="white" light expand="md" fixed="top" style={{border: "1px solid #eee"}}>
                <Navbar className="content-container" style={{paddingLeft: '15px', paddingRight: '15px'}} color="white" light expand="md" >
                    <NavbarBrand href="https://www.berkeassessment.com">
                        <img src="logo.png" alt="Berke" className="img-responsive"/>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="navbar-menu">
                                Features
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://www.berkeassessment.com/features">
                                        Overview
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/features/assessment">
                                        Assessment
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/features/hiring-profiles">
                                        Hiring Profiles
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/features/benchmark-studies">
                                        Benchmark Studies
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/features/reports">
                                        Reports
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/features/applicant-tracking">
                                        Applicant Tracking Integrations
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <NavLink href="https://www.berkeassessment.com/pricing" className="navbar-menu">Pricing</NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="navbar-menu">
                                Solutions
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://www.berkeassessment.com/solutions">
                                        Pre-Employment Testing
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/solutions/aptitude-testing">
                                        Aptitude Assessment
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/solutions/personality-testing">
                                        Personality Assessment
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/solutions/skills-testing">
                                        Skills Assessment
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="navbar-menu">
                                Resources
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://www.berkeassessment.com/blog">
                                        Blog
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/resources/how-to">
                                        Knowledge Base
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/resources/guides">
                                        Guides
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/support">
                                        Support
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="navbar-menu">
                                About
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://www.berkeassessment.com/about">
                                        About Berke
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/about/contact-us">
                                        Contact Us
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/about/careers">
                                        Careers
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="ml-auto right-bar" navbar>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="contact-us navbar-menu contact-hidden">
                                Contact Us
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://www.berkeassessment.com/about/contact-us">
                                    Call 1-888-220-7611
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/about/contact-us">
                                    Contact Sales
                                    </DropdownItem>
                                    <DropdownItem href="https://www.berkeassessment.com/support">
                                    Support
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <NavLink href="https://www.berkeassessment.com/demo" className="btn btn-primary demo-btn">Request a Demo</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://site.BerkeAssessment.com" className="customer-login-link navbar-menu">Log In</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Navbar>
          </Container>
      </div>
    );
}

export default TopBar