import React from 'react'
import './DisplayElement.css'


const DisplayElement = ({info, index, employeeValue, costValue}) => {
  const turnoverRate = employeeValue.find(employee => employee.id === 2).value
  const avgReduction = costValue.find(cost => cost.id === 0).value
  const expectedAnnual = costValue.find(cost => cost.id === 1).value
  const expectedCost = costValue.find(cost => cost.id === 2).value
  const nFormatter = (num, digits) => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "B" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }
  let displayValue = 0;
  switch (index) {
    case 0: {
        displayValue = Math.round(expectedAnnual-expectedCost) >= 1000000 ? nFormatter(Math.round(expectedAnnual-expectedCost), 1) : Math.round(expectedAnnual-expectedCost) >= 100000 ? nFormatter(Math.round(expectedAnnual-expectedCost), 0) : parseFloat(Math.round(expectedAnnual-expectedCost)).toLocaleString("en");
        displayValue = '$' + displayValue;
        break;
    }
    case 1: {
        displayValue = expectedCost === 0 ? 0 : Math.round((expectedAnnual-expectedCost)/expectedCost*100)
        displayValue = parseFloat(displayValue).toLocaleString("en") + '%';
        break;
    }
    case 2: {
      displayValue = expectedAnnual === 0 ? 0 : Math.round((expectedCost/(expectedAnnual/12))*10)/10
      displayValue = parseFloat(displayValue).toLocaleString("en");
      break;
    }
    case 3: {
      displayValue = Math.round((turnoverRate-turnoverRate*avgReduction/100)*100)/100 + '%'
      displayValue = parseFloat(displayValue).toLocaleString("en") + '%';
      break;
    }
    default:
        break;
  }
  return (
    <div className="display-element">
        <div className="display-ele-title">{info.text}</div>
        <div className="display-ele-body">{displayValue}
        </div>
    </div>
  );
}

export default DisplayElement