import React from 'react'
import EmployeeData from "../EmployeeData"
import CostSaving from "../CostSaving";
import ReplacementCost from "../ReplacementCost";
import ROIofberke from "../ROIofberke";


const Main = props => {
  return (
    <div>
      <EmployeeData />
      <ReplacementCost />
      <CostSaving />
      <ROIofberke />
    </div>
  );
}

export default Main