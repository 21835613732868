import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import './CalcButton.css'
import './ModalStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { changeEmployeeData } from "../../store/action";


const CalcButton = forwardRef(({info, index}, ref) => {
    const dispatch = useDispatch();
    const [employeeValue, setEmployeeValue] = useState(0);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [totalNum1, setNum1] = useState(0);
    const [totalNum2, setNum2] = useState(0);
    const [totalNum3, setNum3] = useState(0);
    const [turnRate, setTurnRate] = useState(0);
    const [totalSalary, setTotalSalary] = useState(0);
    const [totalNum, setNum] = useState(0);
    const [avgAnnual, setAvgAnnual] = useState(0);

    const addCommas = (num) => {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }
    const removeNonNumeric = num => num.toString().replace(/[^0-9.]/g, "");

    const onChangeTotalNum1 = (event) => {
        if (event.target.value === '') {
            setNum1(0);
        } else {
            console.log(event.target.validity.valid)
            console.log(event.target.value)
            setNum1((event.target.validity.valid) ? parseFloat(event.target.value) : totalNum1);
        }
        
    }

    const onChangeTotalNum2 = (event) => {
        if (event.target.value === '') {
            setNum2(0);
        } else {
            setNum2((event.target.validity.valid) ? parseFloat(event.target.value) : totalNum2);
        }
    }

    const onChangeTotalNum3 = (event) => {
        if (event.target.value === '') {
            setNum3(0);
        } else {
            setNum3((event.target.validity.valid) ? parseFloat(event.target.value) : totalNum3);
        }
    }

    const onChangeTotalSalary = (event) => {
        if (event.target.value === '') {
            setTotalSalary(0);
        } else {
            setTotalSalary((event.target.validity.valid) ? parseFloat(event.target.value) : totalSalary);
        }
    }

    const onChangeTotalNum = (event) => {
        if (event.target.value === '') {
            setNum(0);
        } else {
            setNum((event.target.validity.valid) ? parseFloat(event.target.value) : totalNum);
        }
    }

    const onChangeEmployeeValue = (event) => {
        if (event.target.value === '') {
            setEmployeeValue(0);
        } else {
            const employeeValueWithouZero = event.target.value.replace(/^0+/, '');
            setEmployeeValue((event.target.validity.valid) &&  parseFloat(removeNonNumeric(employeeValueWithouZero).replace('.', '')) < 10000000 ? removeNonNumeric(employeeValueWithouZero) : employeeValue);
            // setEmployeeValue(removeNonNumeric(event.target.value));
        }
    }

    const calculateTurnoverRate = () => {
        setEmployeeValue(turnRate);
        toggle1();
    }

    const calculateAvgAnnual = () => {
        setEmployeeValue(avgAnnual);
        toggle2();
    }

    // const increaseEmployeeValue = () => {
    //     setEmployeeValue(employeeValue+1);
    // }

    // const decreaseEmployeeValue = () => {
    //     if (employeeValue === 0) {
    //         return;
    //     }
    //     setEmployeeValue(employeeValue-1);
    // }

    const toggle1 = () => setModal1(!modal1);
    const toggle2 = () => setModal2(!modal2);

    useEffect(() => {
        /* pop up #1 */
        const val1 = parseFloat(totalNum1);
        const val2 = parseFloat(totalNum2);
        const val3 = parseFloat(totalNum3);
        if (totalNum1 === '' || totalNum2 === '' || totalNum3 === '') {
            setTurnRate(0);
        } else if (val1 === 0 && val2 === 0) {
            setTurnRate(0);
        } else {
            const rate = val3/((val1+val2)/2)*100;
            setTurnRate(Math.round(rate*100)/100);
        }
        /* pop up #2 */
        const val4 = parseFloat(totalSalary);
        const val5 = parseFloat(totalNum);
        if (totalSalary === '' || totalNum === '') {
            setAvgAnnual(0);
        } else if (val5 === 0) {
            setAvgAnnual(0);
        } else {
            const salary = val4/val5;
            setAvgAnnual(Math.round(salary*100)/100);
        }
    }, [totalNum1, totalNum2, totalNum3, totalSalary, totalNum])

    useEffect(() => {
        /* update store */
        dispatch(changeEmployeeData({ id: index, value: employeeValue }))
    }, [index, dispatch, employeeValue])

  return (
    <div className="calc-button">
        { info.flag ? 
        (
            <FontAwesomeIcon 
                icon="question-circle" 
                className="font-question-style" 
                onClick={() => {info.text === 'Turnover Rate (%)' ? toggle1() : toggle2()}} 
            />
        ) 
        : (<div style={{height: "24px"}}></div>)
        }
        <div className="calc-button-title">{info.text}</div>
        <div className="calc-button-body">
            {/* <FontAwesomeIcon icon="minus-circle" onClick={decreaseEmployeeValue} className="font-btn-style" /> */}
            {parseInt(index) === 0 && <Input type="text" autoFocus value={addCommas(employeeValue)} onChange={onChangeEmployeeValue} pattern="^[0-9.,]*" className="calc-input"></Input>}
            {parseInt(index) !== 0 && <Input type="text" value={addCommas(employeeValue)} onChange={onChangeEmployeeValue} pattern="^[0-9.,]*" className="calc-input"></Input>}
            {/* <FontAwesomeIcon icon="plus-circle" onClick={increaseEmployeeValue} className="font-btn-style" /> */}
        </div>
        <Modal isOpen={modal1} toggle={toggle1}>
            <ModalHeader toggle={toggle1} className="modal-header-style">Don’t know your turnover rate?<br/> Not a problem. Simply fill in the information below:</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="totalNum1">Total Number of Employees as of start of calendar year</Label>
                        <Input type="text" value={totalNum1} onChange={onChangeTotalNum1} pattern="^[0-9]*" name="totalNum1" id="totalNum1" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="totalNum2">Total Number of Employees as of end of calendar year</Label>
                        <Input type="text" value={totalNum2} onChange={onChangeTotalNum2} pattern="[0-9]*" name="totalNum2" id="totalNum2" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="totalNum3">Total Number of Separations (voluntary, involuntary) during the year</Label>
                        <Input type="text" value={totalNum3} onChange={onChangeTotalNum3} pattern="[0-9]*" name="totalNum3" id="totalNum3" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="turnoverRate">Turnover Rate (%)</Label>
                        <Input type="text" style={{border: "0px solid"}} value={turnRate} name="turnoverRate" id="turnoverRate" readOnly />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle1} className="modal-footer-btn1">Cancel</Button>{' '}
                <Button onClick={calculateTurnoverRate} className="modal-footer-btn2">Submit</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader toggle={toggle2} className="modal-header-style">Don’t know your average annual salary of separated employees? Simply fill in the information below:</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="totalSalary">Total Combined Salary of Separated Employees ($)</Label>
                        <Input type="text" value={totalSalary} onChange={onChangeTotalSalary} pattern="[0-9]*" name="totalSalary" id="totalSalary" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="totalNum">Total Number of Separated Employees</Label>
                        <Input type="text" value={totalNum} onChange={onChangeTotalNum} pattern="[0-9]*" name="totalNum" id="totalNum" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="avgAnnual">Avg Annual Salary of Separated Employees ($)</Label>
                        <Input type="text" style={{border: "0px solid"}} value={avgAnnual} readOnly name="avgAnnual" id="avgAnnual" />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle2} className="modal-footer-btn1">Cancel</Button>{' '}
                <Button onClick={calculateAvgAnnual} className="modal-footer-btn2">Submit</Button>
            </ModalFooter>
        </Modal>
    </div>
  );
})

export default CalcButton