import React from 'react';
import TopBar from "../../components/landing/TopBar";
import Header from "../../components/landing/Header";
import Footer from "../../components/landing/Footer";
import Main from "../../components/landing/Main";
import "./MainArea.css";

class MainArea extends React.Component {
  render() {
    return (
      <div>
          <TopBar />
          <div>
            <Header />
            <Main />
            <Footer />
          </div>
      </div>
    );
  }
}

export default MainArea;