import React from 'react'
import { useSelector } from 'react-redux';
import './ReplacementCost.css'
import InputElement from '../../elements/InputElement';

const Info = [
  {
      text: "New Employees Needed Due to Turnover",
      symbol: "",
      readOnly: true
  },
  {
      text: "% of Salary Required to Recruit New Employees",
      symbol: "percent",
      readOnly: true
  },
  {
      text: "Total Cost to Recruit New Employees",
      symbol: "dolar",
      readOnly: true
  }
]
const ReplacementCost = props => {
  const employeeValue = useSelector((state) => state.employeeData);
  const replacementValue = useSelector((state) => state.replacementCostData);
  const costValue = useSelector((state) => state.costSavingData);
  return (
    <div className="replacementcost content-container">
      <div className="replacementcost-header">Employee Replacement Cost</div>
      {
          Info.map((item, index) => {
              return (
                <InputElement info={item} key={index} index={index} kind="replacement" employeeValue={employeeValue} replacementValue={replacementValue} costValue={costValue}/>
              );
          })
      }
      <div className="main-divider"></div>
    </div>
  );
}

export default ReplacementCost