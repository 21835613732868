import React from 'react'
import { useSelector } from 'react-redux';
import './ROIofberke.css'
import DisplayElement from '../../elements/DisplayElement';
import { Container, Row, Col } from 'reactstrap';

const Info = [
  {
      text: "Annual NET Savings",
      value: "0"
  },
  {
      text: "Expected ROI",
      value: "0"
  },
  {
      text: "Expected Payback Period in Months",
      value: "0"
  },
  {
      text: "New Turnover Rate",
      value: "0%"
  }
]
const ROIofberke = props => {
  const employeeValue = useSelector((state) => state.employeeData);
  const costValue = useSelector((state) => state.costSavingData);
  return (
    <div className="roiberke-bg">
      <div className="roiberke content-container">
        <div className="roiberke-header">Return On Investment</div>
        <div className="roiberke-description">We have calculated your <b>estimated</b> net savings, expected ROI and payback period of implementing Berke based on the data you have provided and a reduction in turnover assumption based on actual average reductions self-reported by our clients.  For a more accurate estimate, <a href='https://www.berkeassessment.com/demo' target="_blank" rel="noreferrer">connect with a member of our sales team</a></div>
        <Container fluid>
            <Row xs="1" sm="2" md="4">
              {
                  Info.map((item, index) => {
                      return (
                          <Col key={index}>
                              <DisplayElement info={item} index={index} employeeValue={employeeValue} costValue={costValue}/>
                          </Col>
                      );
                  })
              }
            </Row>
        </Container>
      </div>
    </div>
  );
}

export default ROIofberke