import React from 'react'
import { useSelector } from 'react-redux';
import './CostSaving.css'
import InputElement from '../../elements/InputElement';

const Info = [
  {
      text: "Avg Reduction in Turnover with Berke",
      symbol: "percent",
      readOnly: true
  },
  {
      text: "Expected Annual Savings Using Berke",
      symbol: "dolar",
      readOnly: true
  },
  {
      text: "Estimated Cost of Berke Annual Subscription",
      symbol: "dolar",
      readOnly: true
  }
]
const CostSaving = props => {
  const employeeValue = useSelector((state) => state.employeeData);
  const replacementValue = useSelector((state) => state.replacementCostData);
  const costValue = useSelector((state) => state.costSavingData);
  return (
    <div className="costsaving content-container">
      <div className="costsaving-header">Business Impact</div>
      {
          Info.map((item, index) => {
              return (
                <InputElement info={item} key={index} index={index} kind="cost" employeeValue={employeeValue} replacementValue={replacementValue} costValue={costValue}/>
              );
          })
      }
    </div>
  );
}

export default CostSaving