import React from 'react'
import './EmployeeData.css'
import CalcButton from '../../elements/CalcButton';
import { Container, Row, Col } from 'reactstrap';

const Info = [
    {
        text: "Total employees",
        flag: false
    },
    {
        text: "Avg Annual Salary ($)",
        flag: true
    },
    {
        text: "Turnover Rate (%)",
        flag: true
    }
]
const EmployeeData = props => {
  return (
    <div className="employee content-container">
      <div className="employee-header">Employment Data</div>
      <div className="employee-description">To calculate your <b>potential</b> return on investment, Enter the total number of employees at the company <b>at the start of last year</b> along with the average annual salary of separated employees from the prior year.  If you know your turnover rate from the prior year, enter it in the field below.  If you don’t know your turnover rate, you can use our calculation tool by clicking on the question mark next to this field.</div>
      <Container>
          <Row xs="1" sm="1" md="3">
            {
                Info.map((item, index) => {
                    return (
                        <Col key={index}>
                            <CalcButton info={item} index={index} />
                        </Col>
                    );
                })
            }
          </Row>
      </Container>
      <div className="main-divider"></div>
    </div>
  );
}

export default EmployeeData