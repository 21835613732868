import { CHANGE_EMPLOYEE, CHANGE_COSTSAVING, CHANGE_REPLACEMENTDATA } from "./actionType";

export function changeEmployeeData(payload) {
    return {
        type: CHANGE_EMPLOYEE,
        payload
    }
}
export function changeCostSavingsData(payload) {
    return {
        type: CHANGE_COSTSAVING,
        payload
    }
}
export function changeReplacementData(payload) {
    return {
        type: CHANGE_REPLACEMENTDATA,
        payload
    }
}