import { CHANGE_EMPLOYEE, CHANGE_COSTSAVING, CHANGE_REPLACEMENTDATA } from "./actionType";
  
export const initialState = {
    employeeData: [
        { id: 0, value: 0 },
        { id: 1, value: 0 },
        { id: 2, value: 0 }
    ],
    costSavingData: [
        { id: 0, value: 17 },
        { id: 1, value: 0 },
        { id: 2, value: 0 }
    ],
    replacementCostData: [
        { id: 0, value: 0 },
        { id: 1, value: 0 },
        { id: 2, value: 0 }
    ]
  }
  
  // Use the initialState as a default value
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_EMPLOYEE:
            return {
                ...state,
                employeeData: state.employeeData.map(employee => {
                    if (employee.id !== action.payload.id) {
                        return employee
                    }
            
                    // We've found the todo that has to change. Return a copy:
                    return {
                        ...employee,
                        // Flip the completed flag
                        value: action.payload.value
                    }
                })
            };
        case CHANGE_COSTSAVING:
            return {
                ...state,
                costSavingData: state.costSavingData.map(cost => {
                    if (cost.id !== action.payload.id) {
                        return cost
                    }
            
                    // We've found the todo that has to change. Return a copy:
                    return {
                        ...cost,
                        // Flip the completed flag
                        value: action.payload.value
                    }
                })
            };
        case CHANGE_REPLACEMENTDATA:
            return {
                ...state,
                replacementCostData: state.replacementCostData.map(replaceData => {
                    if (replaceData.id !== action.payload.id) {
                        return replaceData
                    }
            
                    // We've found the todo that has to change. Return a copy:
                    return {
                        ...replaceData,
                        // Flip the completed flag
                        value: action.payload.value
                    }
                })
            };
        // Do something here based on the different types of actions
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}

export default reducer;

