import React from 'react'
import './Footer.css'


const Footer = props => {
  return (
    <div style={{backgroundColor: "#E0E0E0"}}>
      <div className="Footer-bg">
        <div className="Footer content-container">
          <div className="Footer-title">Interested in Learning More?</div>
          <div className="Footer-body2">
            <p style={{marginRight: "20px"}}>Connect with a product specialist to get a personalized demo of the platform, and learn how Berke can help you reduce turnover and make more informed hiring decisions.</p>
            <p>
            <a href="https://www.berkeassessment.com/request-a-demo" className="schedule-btn">Schedule Demo</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer