import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './InputElement.css'
import './ModalStyle.css'
import { 
    Container, 
    Row, 
    Col, 
    Input, 
    InputGroup, 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Table
} from 'reactstrap';
import { changeCostSavingsData, changeReplacementData } from "../../store/action";


const InputElement = ({info, kind, index, employeeValue, replacementValue, costValue}) => {
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const toggleModal1 = () => setModal1(!modal1);
    const toggleModal2 = () => setModal2(!modal2);
    const toggleModal3 = () => setModal3(!modal3);
    //////////////////////////////
    const employeeNum = employeeValue.find(employee => employee.id === 0).value
    const avgAnnualSalary = employeeValue.find(employee => employee.id === 1).value
    const turnRate = employeeValue.find(employee => employee.id === 2).value
    const costToReplace = replacementValue.find(replace => replace.id === 1).value
    // const costToReplaceIndividual = replacementValue.find(replace => replace.id === 2).value
    const costToReplaceAll = replacementValue.find(replace => replace.id === 2).value
    const avgReduction = costValue.find(cost => cost.id === 0).value
    // const costToReplaceBerke = costValue.find(cost => cost.id === 1).value
    const newEmployeeNum = employeeNum*turnRate/100;
    //////////////////////////////
    const dispatch = useDispatch();
    const [calcValue, setCalcValue] = useState(0);
    const roundTo = (number, roundto) => {
        return roundto * Math.round(number/roundto);
    }
    const discountPercent = (number) => {
        if (number < 1200) {
            return 125;
        } else if (number < 3000) {
            return 100;
        } else if (number < 6000) {
            return 95;
        } else if (number < 12500) {
            return 85;
        } else if (number < 25000) {
            return 75;
        } else if (number < 50000) {
            return 70;
        } else if (number < 100000) {
            return 65;
        } else if (number < 200000) {
            return 63;
        } else {
            return 60;
        }
    }
    useEffect(() => {
        /* update store */
        if (kind === 'cost') {
            switch (index) {
                case 0: {
                    setCalcValue(17)
                    break;
                }
                // case 1: {
                //     setCalcValue(Math.round(costToReplaceAll*(1-avgReduction/100)))
                //     break;
                // }
                case 1: {
                    setCalcValue(Math.round(costToReplaceAll-Math.round(costToReplaceAll*(1-avgReduction/100))))
                    break;
                }
                case 2: {
                    const compensation = avgAnnualSalary/250*1.5;
                    const priceWithoutDiscount = compensation*newEmployeeNum;
                    const disPercent = discountPercent(priceWithoutDiscount);
                    const priceWithSize = priceWithoutDiscount*disPercent/100;
                    const standard = Math.max(roundTo(priceWithSize, 100), 900);
                    if (compensation === 0) {
                        setCalcValue(0);
                    } else {
                        setCalcValue(roundTo(standard*1.25, 100));
                    }
                    break;
                }
                default:
                    break;
            }
            dispatch(changeCostSavingsData({ id: index, value: calcValue }))
        } else {
            switch (index) {
                case 0: {
                    setCalcValue(Math.round(newEmployeeNum))
                    break;
                }
                case 1: {
                    if (avgAnnualSalary < 30000) {
                        setCalcValue(16)
                    } else if (avgAnnualSalary < 75000) {
                        setCalcValue(20)
                    } else if (avgAnnualSalary > 75000) {
                        setCalcValue(25)
                    }
                    break;
                }
                // case 2: {
                //     setCalcValue(Math.round(avgAnnualSalary*costToReplace/100))
                //     break;
                // }
                case 2: {
                    setCalcValue(Math.round((employeeNum*turnRate/100)*Math.round(avgAnnualSalary*costToReplace/100)))
                    break;
                }
                default:
                    break;
            }
            dispatch(changeReplacementData({ id: index, value: calcValue }))
        }
    }, [kind, index, dispatch, calcValue, employeeNum, turnRate, avgAnnualSalary, costToReplace, costToReplaceAll, avgReduction, newEmployeeNum])
  return (
    <div className="input-element">
        <Container fluid>
            <Row xs="1" sm="2" md="2">
                <Col xs="12" sm="8" md="8" className="input-left-wrap">
                    <div className="input-left">
                        {info.text + ' '}
                        { info.text === '% of Salary Required to Recruit New Employees' ? 
                        (
                            <FontAwesomeIcon 
                                icon="question-circle" 
                                className="font-question-style" 
                                onClick={toggleModal1} 
                            />
                        ) 
                        : info.text === 'Estimated Cost of Berke Annual Subscription' ? 
                        (
                            <FontAwesomeIcon 
                                icon="question-circle" 
                                className="font-question-style" 
                                onClick={toggleModal2} 
                            />
                        ) 
                        : info.text === 'Avg Reduction in Turnover with Berke' ?
                        (
                            <FontAwesomeIcon 
                                icon="question-circle" 
                                className="font-question-style" 
                                onClick={toggleModal3} 
                            />
                        ) 
                        : ''
                        }
                    </div>
                </Col>
                <Col xs="12" sm="4" md="4">
                    <InputGroup>
                        { info.symbol === 'dolar' ? 
                            <Input type="text" className="input-right" value={'$' + parseFloat(calcValue).toLocaleString("en")} pattern="[0-9]*" readOnly={info.readOnly}></Input>
                        : info.symbol === 'percent' ? 
                            <Input type="text" className="input-right" value={parseFloat(calcValue).toLocaleString("en") + '%'} pattern="[0-9]*" readOnly={info.readOnly}></Input>
                        : 
                            <Input type="text" className="input-right" value={parseFloat(calcValue).toLocaleString("en")} pattern="[0-9]*" readOnly={info.readOnly}></Input>
                        }
                    </InputGroup>
                </Col>
            </Row>
        </Container>
        <Modal isOpen={modal1} toggle={toggleModal1}>
            <ModalHeader toggle={toggleModal1} className="modal-header-style">Average cost % of salary to replace a salaried employee:</ModalHeader>
            <ModalBody className="modal-body-style">
                <div>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Salary</th>
                                <th>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{'< '}$30000</td>
                                <td>16%</td>
                            </tr>
                            <tr>
                                <td>$30000 - $75000</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>$75000+</td>
                                <td>25%</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div style={{fontSize: "14px"}}>* This data is from the Center for American Progress (CAP) Report, <a href='https://www.americanprogress.org/wp-content/uploads/2012/11/CostofTurnover.pdf' target="_blank" rel="noreferrer">"There are significant business costs to replacing employees"</a></div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleModal1} className="modal-footer-btn1">Close</Button>{' '}
            </ModalFooter>
        </Modal>
        <Modal isOpen={modal2} toggle={toggleModal2}>
            <ModalHeader toggle={toggleModal2} className="modal-header-style">Expected Cost of Berke Annual Subscription: </ModalHeader>
            <ModalBody className="modal-body-style">
                <div>
                    This is an estimated cost of a Berke annual subscription calculated based on the limited data provided.  The actual cost of an annual subscription is influenced by a number of additional factors.  To get a accurate quote, <a href='https://www.berkeassessment.com/demo' target="_blank" rel="noreferrer">connect with a member of our sales team</a>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleModal2} className="modal-footer-btn1">Close</Button>{' '}
            </ModalFooter>
        </Modal>
        <Modal isOpen={modal3} toggle={toggleModal3}>
            <ModalHeader toggle={toggleModal3} className="modal-header-style">Avg Reduction in Turnover with Berke: </ModalHeader>
            <ModalBody className="modal-body-style">
                <div>
                    <p>17% represents the floor reduction in turnover as self-reported by Berke customers who have tracked pre/post turnover following implementation. Customers have reported reductions as high as 60%. 17% is the minimum reported turnover</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleModal3} className="modal-footer-btn1">Close</Button>{' '}
            </ModalFooter>
        </Modal>
    </div>
  );
}

export default InputElement