import React from 'react'
import './Header.css'


const Header = props => {
  return (
    <div className="Header">
      <div className="content-container">
        <div className="Header-title">Real Savings</div>
        <div className="Header-body" style={{paddingBottom: "73px"}}>Pre-employment assessments deliver real savings in the form of reductions in turnover, often paying back the initial investment in months.  To illustrate this, we have created a tool to help calculate your <b>potential</b> return on investment after successfully implementing Berke.</div>
      </div>
    </div>
  );
}

export default Header